import React from "react"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import Hero from '../containers/home-banner/hero'
import Story from '../containers/home-banner/story'
import ProjectSection from '../containers/home-banner/project'
import TestimonialSection from '../containers/home-banner/testimonial'

const HomeBannerPage = (props) => {
	const langKey = props.pageContext.langKey;
	return (
		<Layout lang={langKey}>
			<Seo title="الرئيسية" lang={langKey} />
			<Hero lang={langKey} />
			<Story lang={langKey} />
			<ProjectSection lang={langKey} />
			<TestimonialSection lang={langKey} />
		</Layout>
	)
} 

export default HomeBannerPage;
